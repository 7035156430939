import React from 'react';
import getImage from 'helpers/getImage';
import Avatar from '@mui/material/Avatar';

const DealerAvatar = ({ imageId, imageVcode, dealerName }) => {
  const image = getImage(imageId, imageVcode, 50, 64, 64);

  return (
    <>
      <Avatar alt={`${dealerName} Logo`} src={image} />
    </>
  );
};

export default DealerAvatar;
