import getEnvVarByName from './getEnvVarByName';
import getTransformationInfo from './getImageTransformInfos';

const domainImages = getEnvVarByName('API_IMAGE_BASE_URL');

const getImage = (
  imageId: number,
  imagevCode: string,
  quality?: number,
  width?: number,
  height?: number,
  crop?: boolean,
  gravity?: string,
  radius?: number,
  backgroundColor?: string,
  png?: boolean,
) => {
  if (!imageId) return require('assets/default-image.svg').default;
  return `${domainImages}/images/v1.0/${imageId}/transform/${getTransformationInfo(
    quality,
    width,
    height,
    crop,
    gravity,
    radius,
    backgroundColor,
    png,
  )}?vcode=${imagevCode}`;
};

export default getImage;
